import axios from "axios";

export const fetchData = async (baseDomain) => {
  try {
    console.log(baseDomain);
    const response = await axios.get(`${baseDomain}`);
    return response.data;
  } catch (error) {
    console.log(error); 
    throw new Error(error.message);
  }
};


export const postData = async(req)=>{
  try{
     const response = await axios.post('https://demo-apis.json-server.dev/place-order' ,req); 
     return response.data; 
  }
  catch(err){
          throw new Error(err.message); 
  }
}


