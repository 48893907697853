import React, { useState } from 'react';
import { Form, Button, Navbar, Nav, Container, Col, Row, Modal } from 'react-bootstrap';
import './Navbar.css';
import imagePath from './service_virtualization.jpg'

function CollapsibleExample({ loadData, handleUpdateBaseDomain, title }) {
  console.log(title);
  
  const [domain, setDomain] = useState(() => {
    try {
      const item = window.localStorage.getItem("domainValue");
      return item ? JSON.parse(item) : 'https://demo-apis.json-server.dev';
    } catch (err) {
      console.log(err);
      return 'https://demo-apis.json-server.dev';
    }
  });
  
  const [show, setShow] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleInfoClose = () => setShowInfoModal(false);
  const handleInfoShow = () => setShowInfoModal(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleUpdateBaseDomain(domain);
    handleClose();
  };

  const handleReset = (event) => {
    event.preventDefault();
    const tempDomain = "https://demo-apis.json-server.dev";
    setDomain(tempDomain);
    handleUpdateBaseDomain(tempDomain);
    handleClose();
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <h3>{title}</h3>
          </Nav>
          <Nav>
            <Button type="button" variant="light" onClick={loadData}>
              <i className="bi bi-arrow-clockwise fs-3"></i>
            </Button>
            <Button type="button" variant="light" onClick={handleShow}>
              <i className="bi bi-gear fs-3"></i>
            </Button>
            <Button type="button" variant="light" onClick={handleInfoShow}>
              <i className="bi bi-info-circle fs-3"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>API Base Domain</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextDomain">
                    <Form.Label column sm="3">
                      Domain
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" value={domain} onChange={(e) => setDomain(e.target.value)} />
                    </Col>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button variant="secondary" onClick={handleReset} className="me-auto">
                  Reset
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showInfoModal} onHide={handleInfoClose} dialogClassName='imageModal'>
              <Modal.Header closeButton>
                <Modal.Title>Deployment Architecture</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img src={imagePath} alt="service-virtualization" className="img-fluid" />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleInfoClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
