import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import TitlePage from "./TitlePage"; 



const App = () => {
  return (
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/setting" element={<TitlePage />} />
      </Routes>
     </BrowserRouter>
  );
};

export default App;








