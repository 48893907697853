import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import { postData } from './apiServices';
import {  toast } from 'react-toastify';
import Loader from './Loader'
import './Home.css'


function Buy(data) {

    const [show, setShow] = useState(false);
    const [amount,setAmount] = useState('');
    const [quantity,setQuantity] = useState('');
    const [answer,setAnswer] = useState(null); 
    const [toastMessage,setToastMessage]= useState(null);
    const [loading,setLoading]  = useState(false); 

    const handleClose = () => {
      setShow(false);
      setAnswer(''); 
      setAmount(''); 
      setQuantity(''); 
    }

    const handleShow = () => setShow(true);

    const handleSubmit = async(event)=>{
      event.preventDefault();
      setLoading(true); 
        try{
          const result = await postData({
            symbol:data.symbol,
            amount:amount,
            quantity:quantity
          })
         setAnswer(result); 
         setToastMessage({type:'success',message:"Order Placed successfully"})
        }
        catch(err){
         setToastMessage({type:'error',message:err.message})
        }
        finally{
          setLoading(false); 
        }
      handleClose();
    }


useEffect(()=>{

  if(toastMessage){
    if(toastMessage.type === 'success'){
      toast.success(toastMessage.message,{
        autoClose:3000
      })
    }
    else if(toastMessage.type === 'error'){
      toast.error(toastMessage.message,{
        autoClose:3000
      })
    }
  }
},[toastMessage])


  return (
    <>
       
      {loading ? 
      (
        <div className="loader-container">
            <Loader />
          </div>
      ):
      (
        <>
        <ButtonGroup>
        <Button  onClick={handleShow}  variant="info" size='sm'>Buy</Button>
          <Button variant="warning" size='sm'>Sell</Button>
        </ButtonGroup>
          
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buy Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextDomain">
                  <Form.Label column sm="3">
                    Symbol
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control type="text" disabled value={data.symbol} />
                  </Col>
                </Form.Group>


                <Form.Group as={Row} className="mb-3" controlId="formPlaintextDomain">
                  <Form.Label column sm="3">
                    Amount
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control type='text' value={amount} onChange={(e)=>{
                        setAmount(e.target.value)
                    }} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextDomain">
                  <Form.Label column sm="3">
                    Quantity
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control type="text"  value={quantity} onChange={(e)=>{
                        setQuantity(e.target.value)
                    }}/>
                  </Col>
                </Form.Group>

              </Form>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Place order
          </Button>
        </Modal.Footer>
      </Modal>
      </>
      )
      }
       
    </>
  )
}

export default Buy
