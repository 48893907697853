import React, { useState, useEffect } from 'react';
import { fetchData } from './apiServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Container, Table } from 'react-bootstrap';
import Loader from './Loader';
import Navbar from './Navbar';  
import './Home.css';    
import Buy from './Buy';

const Home=() =>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [baseDomain, setBaseDomain] = useState(()=>{
        try{
             const initialValue = 'https://demo-apis.json-server.dev';
             const item = window.localStorage.getItem("domainValue"); 
             return item? JSON.parse(item):initialValue; 
        }
        catch(err){
          console.log(err); 
          return 'https://demo-apis.json-server.dev';
        }
    });
  
    const loadData = async () => {
      setData([]);
      setLoading(true);
      try {
        const baseApi = baseDomain+"/loan-clos"
        const result = await fetchData(baseApi);
   
       console.log(result);       const formattedData = Array.isArray(result) ? result : [result];
        setData(formattedData);
        if (result.length>0) {
          toast.success('Information retrieved', {
            autoClose: 3000,
          });
        } else {
          toast.info('No data available', {
            autoClose: 3000,
          });
        }
      }

        catch(err){
          toast.error(err.message,{
            autoClose:3000
          })
        }

      finally {
        setLoading(false);
      }
    };
  
    const updateBaseDomain = (newDomain) => {
      window.localStorage.setItem("domainValue",JSON.stringify(newDomain)); 
      setBaseDomain(newDomain);
    };
  
    useEffect(() => {
      loadData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
    },[baseDomain]);


    const [title,setTitle] = useState(()=>{
      try{
        const item = window.localStorage.getItem("givenTitle"); 
       return item ? JSON.parse(item) :'CLO Listing'
     }
     catch(err){
      console.log(err); 
      return 'CLO Listing'
     }
    })
       
  
    return (
      <>
      
        <Navbar handleUpdateBaseDomain={updateBaseDomain} loadData={loadData} title={title}/>
        <ToastContainer />
        {loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          data && (
            <>
            
              <Container>
                <br />
                <Table striped bordered hover size='sm' className='table-sm'> 
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Product</th>
            <th style={{textAlign:'right'}}>Spot Line</th>
            <th style={{textAlign:'right'}}>Loan Amount (M)</th>
            <th style={{textAlign:'right'}}>Unpaid Amount</th>
            <th style={{textAlign:'right'}}>Interest Rate</th>
            <th style={{textAlign:'center'}}></th>
          </tr>
        </thead>
          <tbody>
  
          {data.map((item, index) => (
                <tr>
                  <td style={{textAlign:'center'}}>{item.symbol}</td>
                  <td style={{textAlign:'right'}}>Buy: {item.spotBuyLine} Sell: {item.spotSellLine}</td>
                  <td style={{textAlign:'right'}}>{item.loanAmount}</td>
                  <td style={{textAlign:'right'}}>{item.unpaidAmount}</td>
                  <td style={{ textAlign: 'right' }}>{item.interestRate}</td>
                  <td style={{textAlign:'center'}}>
                  <Buy symbol={item.symbol}/>
                  </td>
                </tr>
          ))}
          </tbody>
  
        </Table>
              </Container>
            </>
          )
        )}
      </>
    );
}

export default Home
